<template>
  <span
    :class="className ? className : 'regular-12 text-black ms-1'"
    v-on:dblclick="edit = true"
    v-if="!edit && !alwaysInput"
  >
    {{
      $t(`cataloge.key`, {
        expr:
          value?.name !== null && value?.name?.length > 0 ? value?.name : " - ",
      })
    }}
  </span>
  <v-select
    v-if="edit || alwaysInput"
    v-model:options="searchItems"
    v-on:search="(s, k) => fetchCrmItem(s, k)"
    v-model="value"
    :filterable="false"
    :placeholder="$t(`crmItemFinder.selectOne`)"
    v-on:focusin="edit = true"
    v-on:focusout="edit = false"
    append-to-body
    :dropdown-should-open="() => edit"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching && search.length > 2">
        {{ $t("crmItemFinder.noItemFoundFor") }} <em>{{ search }}</em
        >.
      </template>
      <span v-else> {{ $t("crmItemFinder.startTypingToSearch") }}</span>
    </template>
    <template v-slot:selected-option="option">
      {{ option.name }}
    </template>
    <template v-slot:option="option">
      {{ option.name }}
    </template>
  </v-select>
</template>

<script>
import http from "../../../modules/http";

export default {
  name: "CrmItemFinder",
  props: {
    id: Number,
    type: String,
    className: String,
    row: Object,
    alwaysInput: {
      type: Boolean,
      default: false,
    },
    modelValue: Number,
    reduce: Function,
    products: Array,
  },
  data() {
    return {
      value: this.modelValue,
      searchItems: [],
      edit: false,
      noedit: false,
    };
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.searchByProducts();
      },
    },
    value: {
      deep: true,
      handler() {
        if (typeof this.reduce === "function") {
          this.noedit = true;
          let val = this.reduce(Object.assign({}, this.value));
          this.$emit("update:modelValue", val);
          this.$emit("update", val);
          return;
        }

        this.$emit("update:modelValue", this.value);
        this.$emit("update", this.value);
      },
    },
    modelValue() {
      if (this.noedit) {
        this.noedit = false;
        return;
      }
      this.value = this.modelValue;
    },
  },
  mounted() {
    if (this.products?.length > 0) {
      this.searchByProducts();
    }
  },
  emits: ["update:modelValue", "update"],
  methods: {
    searchByProducts() {
      http
        .fetch(`/crm/${this.type ?? "teams"}/search`, {
          products: this.products,
        })
        .then((data) => {
          this.searchItems = data;
        });
    },
    fetchCrmItem(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch(`/crm/${this.type ?? "teams"}/search`, {
          q: search,
        })
        .then((data) => {
          this.searchItems = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
  },
};
</script>
