<template>
  <div
    class="toast fade popup position-fixed text-start toast-wide"
    aria-live="assertive"
    aria-atomic="true"
    ref="modal"
    :style="{ top: positionY + 'px', left: positionX + 'px' }"
  >
    <div class="toast-header">
      <strong class="me-auto">
        {{ $t("purchasePrices.editPurchasePrice") }}
      </strong>
      <button
        type="button"
        class="btn-close"
        v-on:click="closeModal(false)"
        :aria-label="$t(`orderChange.close`)"
      ></button>
    </div>
    <div
      class="toast-body"
      ref="body"
      v-on:focusout="closeModal(true)"
      v-on:mouseleave="focusOut = true"
      v-on:mouseenter="focusOut = false"
    >
      <div class="mt-3">
        <div
          class="row align-items-center custom-input whitebg-input mb-1"
          v-for="(price, i) in modelValueData"
          :key="price.id"
        >
          <div class="col-6">
            <div>
              <b>{{ price.name }}</b>
              <CrmItemFinder type="partners" v-model="price.partner" />
            </div>
          </div>
          <div class="col-4">
            <div class="fix-end-input">
              <input
                type="number"
                class="form-control"
                v-model="price.amount"
                step="0.01"
              />
              <select v-model="price.currency" class="mx-1">
                <option
                  :value="value.code"
                  :key="value.code"
                  v-for="value in currencies"
                >
                  {{ value.code }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <button
              class="btn btn-small btn-sm btn-outline-primary"
              v-on:click="deleteRow(i)"
            >
              <BaseIcon name="trash" />
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-outline-primary" v-on:click="addNewRow()">
          {{ $t("purchasePrices.addNew") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import CrmItemFinder from "../lists/listItems/CrmItemFinder.vue";
import BaseIcon from "../icons/BaseIcon.vue";

const bootstrap = require("bootstrap");

export default {
  name: "PurchasePrices",
  props: {
    modelValue: Object,
    show: Boolean,
    positionY: Number,
    positionX: Number,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      modelValueData:
        typeof this.modelValue === "object" ? this.modelValue : {},
      focusOut: false,
    };
  },
  mounted() {
    let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }
  },
  computed: {
    currencies() {
      const store = useStore();
      return store.state.currencies;
    },
    countries() {
      const store = useStore();
      return store.state.countries;
    },
  },
  methods: {
    addNewRow() {
      if (this.modelValueData === undefined || this.modelValueData === null) {
        this.modelValueData = [];
      }
      this.modelValueData.push({});
    },
    deleteRow(i) {
      this.modelValueData.splice(i, 1);
    },
    closeModal(checkFocus = false) {
      if (checkFocus) {
        if (this.$refs.body.contains(document.activeElement)) {
          return;
        }
        const _this = this;
        setTimeout(function () {
          if (
            _this.$refs.body.contains(document.activeElement) ||
            !_this.focusOut
          ) {
            return;
          }
          _this.$emit("update:modelValue", _this.modelValueData);
          _this.$emit("change");
          _this.$emit("update:show", false);
        }, 300);
      } else {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("change");
        this.$emit("update:show", false);
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        // this.$emit("update:modelValue", this.value);
      },
    },
    show() {
      let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
      if (this.show) {
        myModal.show();
        this.$refs.imp.focus();
      } else {
        myModal.hide();
      }
    },
  },
  components: { BaseIcon, CrmItemFinder },
};
</script>
